.search__input {
  display: flex;
  align-items: center;
  border: 0.01rem solid lightgray;
  height: 3rem;
  padding: 0.5rem;
  border-radius: 999rem;
  width: 75vw;
  margin: auto;
  margin-top: 40px;
  max-width: 75%;
}

.search__input > input {
  flex: 1;
  padding: 0.5rem;
  border: none;
  font-size: medium;
}

.search__input > input:focus {
  outline-width: 0;
}

.search__inputIcon {
  color: gray;
}

.search__buttons {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.search__buttons button {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: #f8f8f8;
  border: 0.01rem solid white;
  text-transform: inherit;
  color: #5f6368;
}

.search__buttons button:hover {
  box-shadow: 0.01rem rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-color: #f8f8f8;
  border: 0.01rem solid #c6c6c6;
  color: #222;
}

.search__buttonsHidden {
  display: none !important;
}
