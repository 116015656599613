.searchPage__header {
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: #ffc72c;
  z-index: 100;
  align-items: flex-start;
  padding: 1rem;
  border-bottom: 0.01rem solid lightgray;
}

.searchPage__logo {
  object-fit: contain;
  height: 3.5rem;
  margin-right: 1.5rem;
}

.searchPage__headerBody > .search > .search__input {
  margin-top: 0;
  width: unset;
  margin: unset;
  max-width: unset;
}

.searchPage_options {
  display: flex;
  align-items: center;
  color: grey;
}

.searchPage_optionsLeft,
.searchPage_optionsRight {
  display: flex;
}

.searchPage_optionsLeft a,
.searchPage_optionsRight a {
  text-decoration: none;
  color: grey;
}

.searchPage_optionsRight {
  margin-left: 2rem;
}

.searchPage_option {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.searchPage_option a {
  margin-left: 1rem;
}

.searchPage__result {
  margin: 1rem;
}

.searchPage__resultCount {
  color: #70757a;
  font-size: 14px;
}

.searchPage__results {
  max-width: 95%;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
}

.searchPage__resultTitle {
  text-decoration: none;
}

.searchPage__resultTitle:hover {
  text-decoration: underline;
}

.searchPage__resultTitle > h2 {
  font-weight: 500;
}
.searchPage__resultSnippet {
  margin-top: 0.5rem;
}

.searchPage__resultImage {
  height: 8rem;
  width: 20rem;
  object-fit: contain;
  margin-right: -20rem;
  margin-top: 0.5rem;
  padding-bottom:1rem;
}
