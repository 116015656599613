.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #ffc72c;
}

.home__header {
  display: flex;
  justify-content: space-between;
  padding: 3rem;
  align-items: center;
}

.home__header a {
  margin-right: 2rem;
  text-decoration: inherit;
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
}

.home__header a:hover {
  text-decoration: underline;
}

.home__headerRight {
  display: flex;
  align-items: center;
  min-width: 13vw;
  justify-content: space-between;
}

.home__headerRight > .MuiSvgIcon-root {
  margin-right: 2rem;
}

.home__body > img {
  object-fit: contain;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: 10rem;
}

.home__body {
  flex: 1;
  display: flex;
  margin-top: 10%;
  flex-direction: column;
}

.centered {
  text-align: justify;
  background-color: whitesmoke;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  padding: 1%;
  width: 80%;
}
